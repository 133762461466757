const setUser = (userObj) => {
  return {
    type: 'SET_USER',
    payload: userObj,
  }
}

const setUserPrivileges = (Obj) => {
  return {
    type: 'SET_USER_PRIVILEGES',
    payload: Obj,
  }
}

const logOut = () => {
  return {
    type: 'LOG_OUT',
  }
}
const exportedObject = {
  setUser,
  setUserPrivileges,
  logOut,
}
export default exportedObject
