import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          masterData: 'Master data',
          doctorsData: "Doctors' menu",
          profiles: 'Profiles',
          adminUsers: 'Admin Users',
          reports: 'Reports',
          statistics: 'Statistics',
          report: 'Report',
          assessments: 'Assessments',
          assessment: 'Assessment',
          name: 'Name',
          update: 'Update',
          delete: 'Delete',
          add: 'Add',
          email: 'Email',
          changePassword: 'Change Password',
          password: 'Password',
          confirm: 'Veuillez confirmer votre action ?',
          cancel: 'Cancel',
          choices: 'Choices',
          choice: 'Choice',
          title: 'Title',
          linkedTo: 'Linked To',
          shortText: 'Short Text',
          longText: 'Long Text',
          titleQuestion: 'Title Question',
          linkedToQuestion: 'Linked to Question',
          withText: 'Includes Text',
          save: 'Save',
          reportResults: "Report's results",
          results: 'results',
          print: 'print',
          assessmentName: 'Assessment name',
          assessmentDate: 'Assessment Date',
          patientFullName: "Patient's First and last name",
          patientBirthDate: 'Patient BirthDate',
          doctorFullName: "Doctor's First and last name",
          referencedBy: 'Referred by',
          peoplePresent: 'People present at the interview',
          positions: 'Positions',
          position: 'Position',
          isDoctor: 'Is a doctor',
          bookings: 'Bookings',
          doctor: 'Doctor',
          from: 'From',
          to: 'To',
          patient: 'Patient',
          patients: 'The patients',
          phone: 'Phone',
          email: 'Email',
          fatherName: "Father's Name",
          motherName: "Mother's Name",
          patientsEvaluations: 'Patients',
          patientEvaluation: 'Patient',
          insertedSuccessfully: 'Inserted successfully',
          updatedSuccessfully: 'Updated successfully',
          notes: 'Notes',
          note: 'Note',
          attachments: 'Attachments',
          attachment: 'Attachment',
          branch: 'Branch',
          history: 'History',
          appointments: 'Appointments',
          related_to: 'Related To',
          relation_type: 'Type of Relation',
          status: 'Status',
          birthDate: 'Birthdate',
          age: 'Age',
          objective: 'Objective',
          decision: 'Decision',
          contentCategories: 'Content Categories',
          contents: 'Content Categories',
          diagnostics: 'Diagnostics',
          statuses: 'Statuses',
          link: 'Link',
          signin: 'Sign In to your account',
          login: 'Login',
          dashboard: 'Dashboard',
          schools: 'Schools',
          category: 'Category',
          entranceDate: 'Entrance date',
          gender: 'Gender',
          logout: 'Logout',
          attendance: 'Attendances',
          exitDate: 'Exit Date',
        },
      },
      fr: {
        translation: {
          masterData: 'Administrateurs',
          doctorsData: 'Menu des docteurs',
          profiles: 'Profils',
          adminUsers: 'Administrateurs',
          users: 'Utilisateurs',
          reports: 'Rapports',
          statistics: 'Statistiques',
          report: 'Rapport',
          assessments: 'Anamnèses psychologies',
          assessment: 'Anamnèse psychologie',
          name: 'Nom',
          update: 'Modifier',
          delete: 'Supprimer',
          add: 'Ajouter',
          email: 'Email',
          changePassword: 'Changer le mot de passe',
          password: 'Mot de passe',
          confirm: 'Veuillez confirmer votre action ?',
          cancel: 'Annuler',
          choices: 'Choix',
          choice: 'Choix',
          title: 'Titre',
          linkedTo: 'Lié à',
          shortText: 'Texte court',
          longText: 'Texte long',
          titleQuestion: 'Titre',
          linkedToQuestion: 'Lié à la question',
          withText: 'Avec texte',
          save: 'Sauvegarder',
          reportResults: 'Résultats du rapport',
          results: 'résultats',
          print: 'imprimer',
          assessmentName: "Nom de l'anamnèse psychologie",
          assessmentDate: "Date de l'anamnèse psychologie",
          patientFullName: 'Nom et prénom du bénéficiaire',
          patientBirthDate: 'Date de naissance du bénéficiaire',
          doctorFullName: 'Nom et Prénom du psychologue ',
          referencedBy: 'Référée par',
          peoplePresent: 'Personnes présentes à l’entretien',
          positions: 'Postes',
          position: 'Poste',
          isDoctor: 'Est un professionnel',
          bookings: 'Réservations',
          doctor: 'Professionnel',
          from: 'De',
          to: 'À',
          patient: 'Bénéficiaire',
          patients: 'Les bénéficiaires',
          patientsEvaluations: 'Les bénéficiaires',
          patientEvaluation: 'Bénéficiaire',
          phone: 'Téléphone',
          email: 'Email',
          fatherName: 'Nom du père',
          motherName: 'Nom de la mère',
          insertedSuccessfully: 'Inséré avec succès',
          updatedSuccessfully: 'Mis à jour avec succés',
          notes: 'Remarques',
          note: 'Compte-rendu',
          attachments: 'Pièces jointes',
          attachment: 'Pièces jointe',
          branch: 'Branche',
          history: 'Histoire',
          appointments: 'Rendez-vous',
          related_to: 'Relié à',
          relation_type: 'Type de relation',
          status: 'Statut',
          birthDate: 'Date de naissance',
          age: 'Âge',
          objective: 'Objectif',
          decision: 'Décision',
          contentCategories: 'Catégories de contenu',
          contents: 'Contenu',
          diagnostics: 'Diagnostique',
          statuses: 'Statuts',
          link: 'Lien',
          signin: 'Connectez-vous à votre compte',
          login: 'Connexion',
          dashboard: 'Tableau de bord',
          schools: 'Écoles',
          category: 'Catégorie',
          entranceDate: "Date d'entrée",
          gender: 'Genre',
          logout: 'Déconnecter',
          attendance: 'Présences',
          exitDate: 'Date de sortie',
        },
      },
    },
    lng: 'fr', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export default i18n
