
const setMenuOptionsShow = (state,val) => {
    return {
        type: "SET_MENU_OPTIONS",
        [state]: val
    }
}

const exportedObject = {
    setMenuOptionsShow,
};
export default exportedObject;