const currentUser = (state = {}, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
        loggedIn: true,
      }

    case 'SET_USER_PRIVILEGES':
      return {
        ...state,
        priviliges: action.payload,
      }
    case 'LOG_OUT':
      return {
        ...state,
        user: {},
        loggedIn: false,
      }
    default:
      return state
  }
}

export default currentUser
